import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import imageUrl from "../../assets/images/Certificeringen en Brancheverenigingen 4.png";

function Split() {
  const { ref: leftRef, inView: leftInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const { ref: rightRef, inView: rightInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const leftControls = useAnimation();
  const rightControls = useAnimation();

  React.useEffect(() => {
    if (leftInView) {
      leftControls.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.6 },
      });
    } else {
      leftControls.start({ y: -50, opacity: 0 });
    }
  }, [leftInView, leftControls]);

  React.useEffect(() => {
    if (rightInView) {
      rightControls.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.6 },
      });
    } else {
      rightControls.start({ y: 50, opacity: 0 });
    }
  }, [rightInView, rightControls]);

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 bg-[#D9D9D9]">
      <div className="main-container max-w-full lg:max-w-screen-lg xl:max-w-screen-xl mx-auto my-0">
        <div className="flex flex-col lg:flex-row">
          {/* Left Section */}
          <motion.div
            ref={leftRef}
            animate={leftControls}
            initial={{ y: -50, opacity: 0 }}
            className="w-full lg:w-1/2 p-4"
          >
            <h2 className="text-2xl font-bold text-[#000] mb-6">
              ABU KEURMERK
            </h2>
            <p className="text-lg text-[#000] mb-6">
              Kwaliteit van uitzendwerk en payrolling begint bij het
              ABU-keurmerk. Van groeiende startup tot grote onderneming. Veel
              directeuren, inkoopstrategen, hr-managers en
              procurementmedewerkers vertrouwen al ruim zestig jaar op het
              ABU-keurmerk. Een uitzend- en payrollorganisatie met het
              ABU-keurmerk:
            </p>
            <ul className="list-disc list-inside space-y-1 mb-6 w-full h-auto rounded-lg border-4 border-[#3c7d8c]">
              <li className="text-lg font-medium text-[#000]">
                Moet zich houden aan de gedragscode
              </li>
              <li className="text-lg font-medium text-[#000]">
                Heeft zijn zaken op orde
              </li>
              <li className="text-lg font-medium text-[#000]">
                Is goed voor zijn uitzendkrachten
              </li>
              <li className="text-lg font-medium text-[#000]">
                Discrimineert niet
              </li>
              <li className="text-lg font-medium text-[#000]">
                Wordt twee keer per jaar gecontroleerd
              </li>
              <li className="text-lg font-medium text-[#000]">
                Zorgt voor verantwoorde huisvesting voor internationale
                medewerkers
              </li>
              <li className="text-lg font-medium text-[#000]">
                Is verplicht de vakbekwaamheid van zijn vaste medewerkers op
                peil te houden door op te leiden.
              </li>
            </ul>
            <p className="text-lg text-[#000]">
              Door middel van deze certificaten kenmerkt DD-Group zich als een
              transparant & betrouwbare partner op het gebied van detachering &
              uitzending van personeel.
            </p>
          </motion.div>

          {/* Right Section */}
          <motion.div
            ref={rightRef}
            animate={rightControls}
            initial={{ y: 50, opacity: 0 }}
            className="w-full lg:w-1/2 p-4 flex flex-col items-center"
          >
            <div className="w-full max-w-sm lg:max-w-full">
              <img src={imageUrl} alt="placeholder" />
            </div>
            <div className="w-full max-w-sm lg:max-w-full p-4 mt-6 rounded-lg border-4 border-[#3c7d8c]">
              <p className="text-lg text-[#000] mb-4">
                Mocht u gebruik willen maken van onze diensten stuurt u dan een
                mailtje naar:{" "}
                <span className="font-bold underline">info@DD-Group.nl</span>
              </p>
              <p className="text-lg text-[#000] mb-4">
                Dan nemen wij zo snel mogelijk contact met u op om de diverse
                mogelijkheden te bespreken.
              </p>
              <p className="text-lg text-[#000] mb-2">
                Marcel Boer & Tjeerd Ligthart
              </p>
              <p className="text-lg text-[#000]">DD-Group</p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Split;
