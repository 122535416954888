import "bootstrap/dist/css/bootstrap.min.css";
import "../src/global.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import Home from "./pages/home/Home";
import Detachering from "./pages/Detachering/Detachering";
import PlayIT from "./pages/PlayIT/PlayIT";
import Opleidingen from "./pages/Opleidingen/Opleidingen";
import BouwInfra from "./pages/bouwInfra/Bouw-Infra";
import Scheepvaart from "./pages/scheepvaart/Scheepvaart";
import Industrie from "./pages/industrie/Industrie";
import InkoopLogistiek from "./pages/inkoopLogistiek/InkoopLogistiek";
import DePloegopdeRoot from "./pages/DePloegopdeRoot/DePloegopdeRoot";
import Partners from "./pages/partners/Partners";
import WiezijnWij from "./pages/WiezijnWij/WiezijnWij";
import CertificeringenenBrancheverenigingen from "./pages/CertificeringenenBrancheverenigingen/CertificeringenenBrancheverenigingen";
import MaatschappelijkVerantwoordOndernemenSponsoring from "./pages/maatschappelijk Verantwoord Ondernemen & Sponsoring/MaatschappelijkVerantwoordOndernemen&Sponsoring";
import KlusTuin from "./pages/klus&Tuin/KlusTuin";
import Inschrijven from "./pages/inschrijven/Inschrijven";
import SocialMediaLink from "./pages/socialmedialink/SocialMediaLink";
import Chatbot from "./components/Chatbot";
import Businessclub from "./pages/businessclub/BusinessClub1";
function App() {
  return (
    <BrowserRouter>
      <Suspense>
        <Chatbot />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/Detachering-En-Werving-&-Selectie"
            element={<Detachering />}
          />
          <Route path="/Play-IT" element={<PlayIT />} />
          <Route path="/Opleidingen" element={<Opleidingen />} />
          <Route path="/Bouw-Infra" element={<BouwInfra />} />
          <Route path="/Scheepvaart" element={<Scheepvaart />} />
          <Route path="/Industrie" element={<Industrie />} />
          <Route path="/InkoopLogistiek" element={<InkoopLogistiek />} />
          <Route path="/Ploeg-Op-De-Root" element={<DePloegopdeRoot />} />
          <Route path="/Partners" element={<Partners />} />
          <Route path="/Wie-zijn-Wij" element={<WiezijnWij />} />
          <Route
            path="/VCU/VCA/NEN-Certificering"
            element={<CertificeringenenBrancheverenigingen />}
          />
          <Route
            path="/MVO-Sponsoring"
            element={<MaatschappelijkVerantwoordOndernemenSponsoring />}
          />
          <Route path="/Klus-&-Tuin" element={<KlusTuin />} />
          <Route path="/Inschrijven" element={<Inschrijven />} />
          <Route path="/Social-Media-Link" element={<SocialMediaLink />} />
          <Route path="/Business-Club" element={<Businessclub />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
