import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import WelcomeImage1 from "../../components/WelcomeImage1";
import ImageTextp from "../partners/ImageTextp";
import ImageTextp2 from "../partners/ImageTextp2";
import Footer from "../../components/Footer";

import backgroundImage from "../../assets/images/partners background image.png";
import imageUrl from "../../assets/images/Terminal Recruiters.png";
import imageUrl2 from "../../assets/images/Logistic Recruiters.png";
import imageUrl3 from "../../assets/images/Mateck Project Support.png";
import imageUrl4 from "../../assets/images/It Solutions World Wide.png";
import imageUrl5 from "../../assets/images/Nexxt It.png";
import imageUrl6 from "../../assets/images/Top Result.png";
import imageUrl7 from "../../assets/images/Tanks.png";
import imageUrl8 from "../../assets/images/The Knowledge.png";

function Partners() {
  const welcomeHeading = <>WELKOM IN DE WERELD VAN AANPAKKERS EN DOORPAKKERS</>;

  const imageTextText = (
    <>
      Terminal Recruiters is de gespecialiseerde dienstverlener voor terminal
      personeel op alle niveaus. Wij hebben jarenlange ervaring met terminal
      werkzaamheden, zowel operationeel als op kantoor, van Rotterdam tot aan
      Antwerpen en Amsterdam.
    </>
  );

  const imageTextText2 = (
    <>
      Openheid en een no-nonsens manier van zaken doen, daar houden wij van.Van
      leerling tot en met Directeur binnen de logistieke sector, ons team zal er
      alles aan doen om vraag en aanbod bij elkaar te brengen. Laat ons netwerk
      voor jou werken!
    </>
  );
  const imageTextText3 = (
    <>
      Mateck is de specialist in het koppelen van gedreven technisch werknemers
      aan de juiste werkgever in de havenindustrie, transport & Logistiek en
      (Petro)chemie. Wij zijn op zoek naar o.a. (allround /flens /service)
      monteurs, pijpfitters, lassers, bankwerkers, industrieel reinigers,
      projectmanagers, (maintenance) engineers, vorkheftruck en reachtruck
      chauffeurs, QHSE specialisten, technician WTB/WTK, werkvoorbereiders, E&I
      technician.
    </>
  );
  const imageTextText4 = (
    <>
      Bij IT Solutions stroomlijnen we uw supply chain met GAP-analyse en op
      maat gemaakte IT-oplossingen. Op basis van de inzichten ontwerpen we het
      projectkader, waarbij we zorgen voor praktische resultaten en duidelijke
      tijdlijnen.
    </>
  );
  const imageTextText5 = (
    <>
      Nexxt-IT biedt uiteenlopende diensten, die uw ICT-vraag vrijwel altijd
      kunnen oplossen.Eén opdracht met meerdere accenten?
      <br />
      Geen probleem. Wij hebben voor elk accent de juiste persoon.
    </>
  );
  const imageTextText6 = (
    <>
      Een sterke samenwerking en motivatie van mensen is voor Top-Result de
      basis voor resultaat en groei.
    </>
  );
  const imageTextText7 = (
    <>
      De wereld verandert snel, ook in de behoudende industrieën. En om die
      verandering voor te blijven, staan bedrijven soms voor challenges waar de
      vraag duidelijk is...maar de solution nog niet. Met iTanks Challenges &
      Solutions worden de vragen opgehaald en de challenges resultaatgericht
      aangepakt met de kennis van het grootste innovatieve industriële netwerk
      van Nederland.
    </>
  );
  const imageTextText8 = <>De opleider voor intern transport en veiligheid</>;

  const linkUrl1 = "https://www.terminalrecruiters.nl/";
  const linkUrl2 = "https://www.logisticrecruiters.nl/";
  const linkUrl3 = "https://www.mateck.nl/";
  const linkUrl4 = "https://www.itsolutionsworldwide.com/";
  const linkUrl5 = "https://nexxt-it.nl/";
  const linkUrl6 = "https://top-result.nl/";
  const linkUrl7 = "https://itanks.eu/?lang=en";
  const linkUrl8 = "https://theknowledge.nl/";

  return (
    <div>
      <Helmet>
        <title>Partners</title>
        <meta
          name="description"
          content="Verken onze gespecialiseerde diensten: Terminal Recruiters verbindt top terminalpersoneel met logistieke rollen van Rotterdam tot Amsterdam. Mateck koppelt gekwalificeerde techprofessionals aan werkgevers in de haven-, transport- en chemische industrieën. IT Solutions stroomlijnt je supply chain met op maat gemaakte IT-oplossingen en duidelijke projectstructuren. Nexxt-IT biedt veelzijdige ICT-oplossingen voor complexe opdrachten, terwijl Top-Result groei stimuleert door sterke samenwerking en effectieve oplossingen."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <WelcomeImage1
          backgroundImage={backgroundImage}
          heading={welcomeHeading}
        />
        <section>
          <ImageTextp
            imageUrl={imageUrl}
            text={imageTextText}
            linkUrl={linkUrl1}
          />
          <ImageTextp2
            imageUrl={imageUrl2}
            text={imageTextText2}
            linkUrl={linkUrl2}
          />
          <ImageTextp
            imageUrl={imageUrl3}
            text={imageTextText3}
            linkUrl={linkUrl3}
          />
          <ImageTextp2
            imageUrl={imageUrl4}
            text={imageTextText4}
            linkUrl={linkUrl4}
          />
          <ImageTextp
            imageUrl={imageUrl5}
            text={imageTextText5}
            linkUrl={linkUrl5}
          />
          <ImageTextp2
            imageUrl={imageUrl6}
            text={imageTextText6}
            linkUrl={linkUrl6}
          />
          <ImageTextp
            imageUrl={imageUrl7}
            text={imageTextText7}
            linkUrl={linkUrl7}
          />
          <ImageTextp2
            imageUrl={imageUrl8}
            text={imageTextText8}
            linkUrl={linkUrl8}
          />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Partners;
