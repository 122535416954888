import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import WelcomeImage from "../../components/WelcomeImage";
import ImageText1 from "../../components/ImageText1";
import ImageText2 from "../../components/ImageText2";
import Footer from "../../components/Footer";

import Button from "./Button";

import backgroundImage from "../../assets/images/business_1.png";
import imageSrc from "../../assets/images/business.png";
import imageUrl from "../../assets/images/business2.png";
import imageUrl2 from "../../assets/images/business3.png";
import imageUrl3 from "../../assets/images/business4.png";
import imageUrl4 from "../../assets/images/business5.png";
import imageUrl5 from "../../assets/images/business6.png";
import fileHref from "../../assets/files/Business club matrix.pdf";

function Businessclub() {
  const imageTextText = (
    <>
      De stap die wij hebben gezet met de oprichting van ons professioneel UCI
      CONTI team vormt niet alleen een uitdaging op sportief en organisatorisch
      gebied maar vraagt ook veel
      <br />
      van ons bestaand sponsornetwerk. De stap naar UCI-niveau vraagt om een
      verhoging van de bestaande financiële middelen. Samen met onze regionale
      partner Terminal Recruiters
      <br />
      hebben wij een Business Club opgericht met het doel het team financieel te
      versterken.
      <br />
      Dit platform is speciaal ontworpen voor bedrijven die ons willen steunen
      en tegelijkertijd willen profiteren van netwerkmogelijkheden, exclusieve
      evenementen en zichtbaarheid <br />
      in het vrouwenpeloton. Door een breder platform van sponsorpakketten te
      creëren wordt het mogelijk om voor een relatief laag bedrag al partner te
      worden van ons team.
    </>
  );
  const imageTextText1 = (
    <>
      Als lid van de Business Club steunt u ons vanzelfsprekend op financieel
      vlak maar ook maakt u automatisch direct deel uit van een groot zakelijk
      netwerk waarin u uw bedrijf kan profileren bij de diverse evenementen en
      koersen. Wij willen u een veel grotere ROI voor uw investering bieden dan
      bij een standaard sponsoring.
      <br />
      De bijgevoegde matrix met de diverse sponsorpakketten geeft de voordelen
      per pakket aan. Er zijn vier niveaus gecreëerd te weten; Platinum, Gold,
      Silver en Bronze.
      <br />
      Per niveau zijn de voordelen beschreven maar naast deze voordelen zijn er
      nog enkele meer te benoemen:
      <br />
      <br />
      <li>
        Social Media-Exposure: Prominente vermelding en promotie op al onze
        sociale mediakanalen (Instagram, Facebook, Twitter, LinkedIn).
      </li>
      <li>
        Social Media Plan: Een gedetailleerd plan voor het promoten van uw merk
        via onze sociale mediakanalen, inclusief posts, stories en live video’s.
        Af te stemmen met elkaar.
      </li>
      <li>
        Marketing en Branding: Mogelijkheid om ons team, met name onze rensters,
        in te zetten voor marketing- en branding activiteiten, zoals fotoshoots
        of meet-and-greets.
      </li>
      <li>
        Volgwagen: De mogelijkheid om mee te gaan in de volgwagen tijdens grote
        wedstrijden.
      </li>
      <li>
        Fotoshoots en Content: Onze rensters bieden fotoshoots voor uw branding
        en maken van posts op hun eigen sociale media kanalen.
      </li>
      <li>
        Publiciteit in de Wedstrijd: Exposure door in de kijker te rijden
        tijdens wedstrijden met tv-publiciteit.
      </li>
      <li>
        Eigen Klanten Uitnodigen: Nodig uw klanten uit voor onze VIP en
        netwerkevenementen, wat bijdraagt aan uw klantrelatiebeheer.
      </li>
      <li>
        Media-Exposure: Regelmatige vermeldingen en artikelen over ons team in
        de pers.
      </li>
    </>
  );

  const imageTextText2 = (
    <>
      <span style={{ fontWeight: "bold" }}>Waarom Kiezen Voor Ons Team?</span>
      <br />
      <span style={{ fontWeight: "bold" }}>Vrouwenwielrennen in de Lift</span>
      <br />
      Het vrouwenwielrennen zit enorm in de lift en biedt een uitstekende
      mogelijkheid voor bedrijven om betrokken te raken bij een groeiende sport.
      Sponsoring op dit niveau kost aanzienlijk minder in vergelijking met het
      mannenwielrennen, terwijl de zichtbaarheid en impact blijven toenemen.
      <br />
      <span style={{ fontWeight: "bold" }}>Gericht en Uitgebreid Bereik</span>
      <br />
      Ons team heeft een sterk en gericht bereik op sociale media met 2000
      volgers op Facebook en 1600 volgers op Instagram. Onze volgers bestaan uit
      mensen uit het wielermilieu, familie, supporters, sponsors en mensen met
      interesse in het wielrennen en de pers. Dit betekent dat uw merk de juiste
      doelgroep bereikt. <br />
      <span style={{ fontWeight: "bold" }}>Unieke Return on Investment</span>
      <br />
      Wij bieden unieke ervaringen zoals VIP arrangementen, exclusieve
      evenementen en de mogelijkheid om mee te gaan in de volgwagen. Deze
      mogelijkheden zorgen voor een sterke return on investment door directe
      betrokkenheid en positieve associaties met uw merk.
      <br />
      <span style={{ fontWeight: "bold" }}>
        Inspiring Ambition en Groeipotentieel
      </span>
      <br />
      Ons project is een meerjarenplan waarbij we elk jaar groei willen creëren.
      Onze rensters hebben duidelijke doelen en ambities, wat resulteert in een
      team dat constant streeft naar verbetering en succes. Uw sponsoring helpt
      ons om deze ambities waar te maken en deel te worden van een inspirerend
      en succesvol verhaal.
    </>
  );
  const imageTextText3 = (
    <>
      <span style={{ fontWeight: "bold" }}>Veelzijdige Exposure</span>
      <br />
      Uw merk krijgt niet alleen zichtbaarheid via onze sociale media en
      website, maar ook door artikelen in de pers en exposure tijdens
      wedstrijden. Onze rensters rijden regelmatig in de kijker, wat zorgt voor
      tv-publiciteit en brede media-aandacht.
      <br />
      <span style={{ fontWeight: "bold" }}>
        Hoge Betrokkenheid en Community Building
      </span>
      <br />
      Onze teamleden en volgers vormen een hechte community die betrokken is bij
      alles wat we doen. Deze betrokkenheid vertaalt zich in een grotere impact
      voor onze sponsors, doordat uw merk wordt gepresenteerd aan een loyale en
      enthousiaste doelgroep.
      <br />
      <span style={{ fontWeight: "bold" }}>
        Flexibiliteit en Aanpasbaarheid
      </span>
      <br />
      Onze sponsorpakketten zijn flexibel en kunnen worden aangepast aan de
      specifieke behoeften en doelen van uw bedrijf. We werken samen met onze
      sponsors om op maat gemaakte oplossingen te bieden die maximale voordelen
      opleveren.
    </>
  );
  const imageTextText4 = (
    <>
      <span style={{ fontWeight: "bold" }}>
        Sluit u aan bij ons succesverhaal
      </span>
      <br />
      Wij nodigen u uit om deel uit te maken van ons succesverhaal door sponsor
      te worden van ons wielerteam. Met uw steun kunnen wij blijven groeien en
      onze doelen bereiken, terwijl uw bedrijf profiteert van de vele voordelen
      die wij bieden.
      <br />
      Voor meer informatie en om een sponsorpakket te bespreken dat aan uw
      behoeften voldoet, kunt u contact met ons opnemen via onderstaande
      gegevens.
    </>
  );

  const backgroundColor = "#D9D9D9";
  const textColor = "black";
  const backgroundColor1 = "#ffff";
  const backgroundColor2 = "#284B5A";
  const textColor2 = "#ffff";

  return (
    <div>
      <Helmet>
        <title>Business Club</title>
        <meta
          name="description"
          content="Word lid van onze Business Club en steun ons UCI CONTI team terwijl je profiteert van exclusieve evenementen en netwerkmogelijkheden."
        />
        <meta
          name="keywords"
          content="Business Club, Sponsorship, UCI CONTI, Women's Cycling"
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <WelcomeImage backgroundImage={backgroundImage} imageSrc={imageSrc} />
        <section>
          <ImageText1 imageUrl={imageUrl} text={imageTextText} />
          <ImageText2
            imageUrl={imageUrl2}
            text={imageTextText1}
            backgroundColor={backgroundColor2}
            textColor={textColor2}
          />
          <ImageText1
            imageUrl={imageUrl3}
            text={imageTextText2}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />
          <ImageText2
            imageUrl={imageUrl4}
            text={imageTextText3}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />
          <ImageText1
            imageUrl={imageUrl5}
            text={imageTextText4}
            backgroundColor={backgroundColor1}
            textColor={textColor}
          />
          <Button fileHref={fileHref} />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Businessclub;
