import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import WelcomeImage from "../../components/WelcomeImage";
import ImageText1 from "../../components/ImageText1";
import ImageText2 from "../../components/ImageText2";
import Footer from "../../components/Footer";

import backgroundImage from "../../assets/images/Play IT Background Image.png";
import imageUrl from "../../assets/images/Play IT 1.png";
import imageUrl2 from "../../assets/images/Play IT 2.png";
import imageUrl3 from "../../assets/images/Play IT 3.png";
import logoUrl from "../../assets/images/Play IT 4.png";
function PlayIT() {
  const welcomeHeading = "Play-IT";

  const imageTextText = (
    <>
      <span style={{ fontWeight: "bold" }}>
        DD-Group is als organisatie een groot voorstander van game-based
        learning!
      </span>
      <br />
      <br />
      Wij zien in de praktijk dat kandidaten veel sneller handelingen en
      procedures onthouden als deze visueel en interactief worden uitgelegd.
      Daarom zijn wij ook trots agent te zijn voor Play-IT. Play-IT brengt
      game-based learning op een hoger niveau!
    </>
  );

  const imageTextText2 = (
    <>
      <span style={{ fontWeight: "bold" }}>
        Mocht u meer informatie willen neemt u dan contact met ons op!
      </span>
      <br />
      <br />
      Wij zullen uw vragen en of wensen doorzetten naar Play-IT met ons unieke
      kenmerk. Hierna zal Play-IT direct contact met u opnemen en voor verdere
      afstemming. Door het unieke kenmerk krijgt u gegarandeerd van Play-IT de
      scherpst mogelijke aanbieding.
    </>
  );
  const imageTextText3 = (
    <>
      <span style={{ fontWeight: "bold" }}>
        ONTSNAP AAN HET TRAININGSDILEMMA
      </span>
      <br />
      <br />
      Optimaliseren met game-based learning
      <br />
      <br />
      Hebben tijdrovende, dure en complexe leermethoden je in de steek gelaten?
      Win tijd terug en houd de aandacht vast met game-based learning. Hectische
      schema's, Verstoringen van het werk, Zoeken naar experts, En onzekerheid
      over retentie van opleidingsinhoud, Klinkt dat bekend? Even voorstellen:
      Play IT, het ultieme platform voor game-based learning - efficiënt leren
      op maat van je schema, altijd en overal. Doe gewoon mee aan korte,
      boeiende en realistische games. Klaar om een level omhoog te gaan?
    </>
  );

  const backgroundColor = "#D9D9D9";
  const textColor = "black";

  return (
    <div>
      <Helmet>
        <title>Play-IT</title>
        <meta
          name="description"
          content="Ontdek beter leren met DD-Group en Play-IT! Onze op games gebaseerde trainingen maken leren leuk en effectief. Neem contact met ons op om Play-IT te verbinden voor op maat gemaakte trainingsoplossingen die aan je behoeften voldoen."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <WelcomeImage
          backgroundImage={backgroundImage}
          heading={welcomeHeading}
        />
        <section>
          <ImageText1
            imageUrl={imageUrl}
            text={imageTextText}
            logoUrl={logoUrl}
          />
          <ImageText2
            imageUrl={imageUrl2}
            text={imageTextText2}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />
          <ImageText1
            imageUrl={imageUrl3}
            text={imageTextText3}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default PlayIT;
